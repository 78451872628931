var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-header',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[(_vm.mailing.value)?_c('form',[_c('div',{staticClass:"form-group col-12 mb-5"},[_c('label',{attrs:{"for":"defaultEmail"}},[_vm._v(" Default email: ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.mailing.value.default),expression:"mailing.value.default"}],staticClass:"form-control",class:{
                                    'is-invalid':
                                        _vm.$v.mailing.value.default.$error
                                },attrs:{"id":"title","type":"text"},domProps:{"value":(_vm.mailing.value.default)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.mailing.value, "default", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-12 mb-5"},[_c('h5',{staticClass:"mb-3 text-uppercase"},[_vm._v(" SetUp training products ")]),(_vm.mailing.value.products)?_c('div',_vm._l((_vm.products),function(product){return _c('div',{key:product.id},[_c('div',{staticClass:"form-group mb-4"},[_c('h5',[_vm._v(_vm._s(product.name))]),_c('label',{attrs:{"for":"productEmails"}},[_vm._v(" Emails: ")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(
                                                _vm.mailing.value.products[
                                                    product.name
                                                ]
                                            ),expression:"\n                                                mailing.value.products[\n                                                    product.name\n                                                ]\n                                            "}],staticClass:"form-control",class:{
                                                'is-invalid':
                                                    _vm.areEmailsValid(product)
                                            },attrs:{"type":"text"},domProps:{"value":(
                                                _vm.mailing.value.products[
                                                    product.name
                                                ]
                                            )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.mailing.value.products, 
                                                    product.name
                                                , $event.target.value)}}})])])}),0):_vm._e()]),_c('div',{staticClass:"form-group col-12 mb-5"},[_c('h5',{staticClass:"mb-2 text-uppercase"},[_vm._v(" Signup Request ")]),_c('label',{attrs:{"for":"signupRequest"}},[_vm._v(" Emails: ")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.mailing.value.signup_request),expression:"mailing.value.signup_request"}],staticClass:"form-control",class:{
                                    'is-invalid':
                                        _vm.$v.mailing.value.signup_request
                                            .$error
                                },attrs:{"id":"signupRequest","type":"text"},domProps:{"value":(_vm.mailing.value.signup_request)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.mailing.value, "signup_request", $event.target.value)}}})]),(_vm.currentUser.role === 'admin')?_c('div',{staticClass:"form-group col-12 mb-5"},[_c('h5',{staticClass:"mb-2 text-uppercase"},[_vm._v(" OffLabel Product Request ")]),_c('label',{attrs:{"for":"offlabelRequest"}},[_vm._v(" Emails: ")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.mailing.value.offlabel_request),expression:"mailing.value.offlabel_request"}],staticClass:"form-control",class:{
                                    'is-invalid':
                                        _vm.$v.mailing.value.offlabel_request
                                            .$error
                                },attrs:{"id":"offlabelRequest","type":"text"},domProps:{"value":(_vm.mailing.value.offlabel_request)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.mailing.value, "offlabel_request", $event.target.value)}}})]):_vm._e(),_c('div',{staticClass:"form-group col-12 d-flex justify-content-end"},[_c('button',{staticClass:"btn btn-success waves-effect waves-light",attrs:{"type":"button"},on:{"click":_vm.save}},[_vm._v(" Save ")])])]):_vm._e()])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }